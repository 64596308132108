export async function fetchData (query, collection) {
    const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${process.env.VUE_APP_CONTENTFUL_SPACE_ID}/environments/master`
    const fetchOptions = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({query})
    }
    try {
        const response = await fetch(fetchUrl, fetchOptions).then(response =>
            response.json()
        )
        if(collection) return response.data[collection].items
        else return response.data
    } catch (error) {
        throw new Error('Something went wrong')
    }
}
//Querys
export  function query_EXECUTIVES(locale){
    return `{
  executivesCollection(
    locale: "${locale}"
    order: position_ASC
  ) {
    items {
      sys {
        id
      }
      firstName
      lastName
      img {
        title
        description
        url
      }
      position
      responsability
      text
      email
    }
  }
}
`
}
export  function query_SPONSORIMAGES(locale){
    return `{
  sponsorCollection(
    locale: "${locale}"
    order: mainSponsor_DESC
    where: {
      type: "Sponsor"
    }
  ){
    items {
      sys {
        id
      }
      url
      logo {
        title
        url
      }
}
  }
}
`
}
export  function query_MAINSPONSORS(locale){
    return `{
  sponsorCollection(
    locale: "${locale}"
    order: mainSponsor_DESC
    where: {
      type: "Sponsor"
       mainSponsor:true
    }
  ){
    items {
      sys {
        id
      }
      name
      url
      sponsorSince
      description
      mainSponsor
      logo {
        title
        description
        url
      }
}
  }
}
`
}
export  function query_SPONSORS(locale){
    return `{
  sponsorCollection(
    locale: "${locale}"
    order: mainSponsor_DESC
    where: {
      type: "Sponsor"
       mainSponsor:false
    }
  ){
    items {
      sys {
        id
      }
      name
      url
      sponsorSince
      description
      mainSponsor
      logo {
        title
        description
        url
      }
}
  }
}
`
}
export  function query_SUPPORTERS(locale){
    return `{
  sponsorCollection(
    locale: "${locale}"
    order: mainSponsor_DESC
    where: {
      type: "Unterstützer"
    }
  ){
    items {
      sys {
        id
      }
      name
      url
      sponsorSince
      description
      logo {
        title
        description
        url
      }
}
  }
}
`
}
export  function query_NEWS(locale){
    return `{
  executivesCollection(
    locale: "${locale}"
    order: position_ASC
    where: {
      OR: [
        { position: "1.Vorsitzende" }
        { position: "2.Vorsitzende" }
        { position: "KassenwartIn" }
      ]
    }
  ) {
    items {
      sys {
        id
      }
      firstName
      lastName
      img {
        title
        description
        url
      }
      position
      responsability
      text
      email
    }
  }
}
`
}
export  function query_LATESTNEWS(locale){
    return `{
  executivesCollection(
    locale: "${locale}"
    order: position_ASC
    where: {
      OR: [
        { position: "1.Vorsitzende" }
        { position: "2.Vorsitzende" }
        { position: "KassenwartIn" }
      ]
    }
  ) {
    items {
      sys {
        id
      }
      firstName
      lastName
      img {
        title
        description
        url
      }
      position
      responsability
      text
      email
    }
  }
}
`
}
export  function query_JUMBOTRONS(locale){
    return `{
  executivesCollection(
    locale: "${locale}"
    order: position_ASC
    where: {
      OR: [
        { position: "1.Vorsitzende" }
        { position: "2.Vorsitzende" }
        { position: "KassenwartIn" }
      ]
    }
  ) {
    items {
      sys {
        id
      }
      firstName
      lastName
      img {
        title
        description
        url
      }
      position
      responsability
      text
      email
    }
  }
}
`
}
export  function query_WORKOUTS(locale){
    return `{
  workoutCollection (
  locale: "${locale}"
  ) {
    items {
      sys {
        id
      }
      name
      type
      weekDay
      workoutStart
      workoutEnd
      description
      coachesCollection
      (limit: 4)
      {
      	items{
          ...on Coaches{
            sys{id}
            firstName
            lastName
          }
      }
    }
      place{
        ...on Places {
          sys{id}
          coords{
          lat
          lon
          }
          name
        }
      }
    }
  }
}
`
}
export  function query_COACHES(locale){
    return `{
  coachesCollection(
    locale: "${locale}"
    ){
    items {
      sys {
        id
      }
      firstName
      lastName
      license
      typeOfSports
      responsabilities
      text
      img{
        title
        description
        url
      }
    }
  }
}
`
}
export  function query_IMPRINT(locale){
    return `{
  textContent(
  id: "5QhD4KwGyz2dtFZUHMw0jD"
  locale: "${locale}"
  ) {
    sys {
      id
    }
    text
  }
}
`
}
export  function query_SAFETYCONCEPT(locale){
    return `{
  textContent(
  id: "3hlUu5k76LA521mPsXRRcc"
  locale: "${locale}"
  ) {
    sys {
      id
    }
    text
  }
     assetCollection(
        where: {
         OR: [
            { sys: { id: "5wjEXkZ9msAIqWQ7SLWzSZ" } }
            { sys: { id: "7JlibLi3701vHv0RNOnmtq" } }
            { sys: { id: "7dCsXhEu3YzuJaKY6zFkfX" } }
        ]
            
    }
) {
        items {
            sys {
                id
            }
            title
            description
            url
        }
    }
}
`
}
export  function query_PRIVACY(locale){
    return `{
  textContent(
  id: "5GJJ8htOBZG0GfFqVGjMFN"
  locale: "${locale}"
  ) {
    sys {
      id
    }
    text
  }
}
`
}
export  function query_ABOUT(locale){
    return `{
  textContent(
  id: "1Kv5FoGNAMeUPvI2uvlmVn"
  locale: "${locale}"
  ) {
    sys {
      id
    }
    text
  }
}
`
}
export  function query_AGENDA(locale){
    return `{
  textContent(
  id: "2gRcEFLdKsqOKSWtD99xBB"
  locale: "${locale}"
  ) {
    sys {
      id
    }
    text
  }
}
`
}
export  function query_TIMELINE(locale){
    return `{
  infoTextCollection(
    locale: "${locale}"
    order: position_ASC
  ) {
    items {
      sys {
        id
      }
    	title
      icon
      text
      date 
    }
  }
}
`
}
export  function query_WORKOUTABOUT(locale){
    return `{
  textContent(
  id: "6V7yoew2bSCv7Pf0xfregy"
  locale: "${locale}"
  ) {
    sys {
      id
    }
    text
  }
}
`
}
export  function query_CONTACTTEXT(locale){
    return `{
  textContent(
  id: "3JCOtSbF5F4MZ8cf7bCesO"
  locale: "${locale}"
  ) {
    sys {
      id
    }
    text
  }
}
`
}
export  function query_FOOTER_LOGOS(){
    return `{
    assetCollection(
        where: {
        OR: [
            { sys: { id: "14X6un641R4zaNo1ZIYAjy" } }
            { sys: { id: "4WQ2nKF2N7P09Bqi1YEpjS" } }
        ]
    }
) {
        items {
            sys {
                id
            }
            title
            description
            url
        }
    }
}
`
}
export  function query_SPORT(locale){
    return `{
    typeOfSportCollection(
     order: sys_publishedAt_ASC
     locale: "${locale}"
    ){
    items {
        sys {
            id
        }
        name
        text
        img{
            title
            description
            url
        }
    }
}
}
`
}
export  function query_PLACES(locale){
    return `{
    placesCollection
    (locale: "${locale}"){
    items {sys {id}
        name
        type
        url
        description
        adress
        coords{lat lon}
        img{
            title
            description
            url
        }
    }
}
}
`
}





<template>
    <section id="safetyConcept">
        <base-card>
          <v-container class="my-5">
            <v-skeleton-loader
                type="paragraph@3"
                :loading="loading"
                class="mt-5"
            >
              <template v-slot:default>
                <v-card-text
                    class="text-xs-center text-lg-justify text-lg-h6 text-sm-subtitle-1 font-weight-light mx-auto my-5"
                    v-html="safetyConcept"
                >
                </v-card-text>
                <v-row justify="space-between" class="pa-6 mb-auto ">
                  <!--v-btn
                      color="secondary"
                      tonal
                      rounded
                      class="logo"
                      :href="safetyConceptDoc"
                      target="_blank"
                  >
                    <v-icon
                        color="white"
                        size="30"
                    >mdi-file-pdf-box
                    </v-icon>
                    {{$t('safetyConcept.safetyConceptPdf')}}
                  </v-btn-->
                  <v-btn
                      color="secondary"
                      tonal
                      rounded
                      class="logo"
                      :href="codeOfHonorDoc"
                      target="_blank"
                  >
                    <v-icon
                        color="white"
                        size="30"
                    >mdi-file-pdf-box
                    </v-icon>
                    {{$t('safetyConcept.codeOfHonorPdf')}}
                  </v-btn>
                  <v-btn
                      color="secondary"
                      tonal
                      rounded
                      class="logo"
                      :href="guidelinesDoc"
                      target="_blank"
                  >
                    <v-icon
                        color="white"
                        size="30"
                    >mdi-file-pdf-box
                    </v-icon>
                    {{$t('safetyConcept.guideLinesPdf')}}
                  </v-btn>
                </v-row>
              </template>
            </v-skeleton-loader>
          </v-container>
        </base-card>
    </section>
</template>

<script>

import {fetchData,query_SAFETYCONCEPT} from "@/utils/api"
import i18n from "@/plugins/i18n"
import showdown from "showdown"

export default {
  data: () => ({
    safetyConcept: {},
    safetyConceptDoc: '',
    codeOfHonorDoc: '',
    guidelinesDoc: '',
    loading: true
  }),
  methods: {
    getsafetyConcept: async () => {
      const query = query_SAFETYCONCEPT(i18n.locale)
      return await fetchData(query)
    }
  },
  async created() {
    const safetyConcept = await this.getsafetyConcept()
    const converter = new showdown.Converter()
    this.safetyConcept = converter.makeHtml(safetyConcept.textContent.text)
    //this.safetyConceptDoc = safetyConcept.assetCollection.items[0].url
    this.codeOfHonorDoc = safetyConcept.assetCollection.items[1].url
    this.guidelinesDoc = safetyConcept.assetCollection.items[2].url
    this.loading = false

    this.$eventHub.$on('locale-changed', async()=> {
      this.loading = true
      const safetyConcept = await this.getsafetyConcept()
      const converter = new showdown.Converter()
      this.safetyConcept = converter.makeHtml(safetyConcept.textContent.text)
      this.safetyConceptDoc = safetyConcept.assetCollection.items[0].url
      this.codeOfHonorDoc = safetyConcept.assetCollection.items[1].url
      this.loading = false
    })
  }
}
</script>
